/**
 * Entry point for all plugin libraries
 * This file is used by browserify to bundle all plugin libraries
 */

// First ensure jQuery is available globally
window.jQuery = window.$ = require('jquery');

// Load ImagesLoaded
window.imagesLoaded = require('imagesloaded');

// Load DataTables 
require('datatables.net')(window, $);

// Load Dropzone - Fix for v6.0.0-beta.2
// Use object destructuring to get the Dropzone constructor
const { Dropzone } = require('dropzone');
window.Dropzone = Dropzone;

// Load Video.js
window.videojs = require('video.js');

// Load LightGallery and its plugins - fix for module imports
const lightgallery = require('lightgallery');
// Check if lightgallery is exported directly or as a default property
window.lightGallery = typeof lightgallery === 'function' 
  ? lightgallery 
  : (lightgallery.default || lightgallery);

// Load LightGallery plugins and handle various module formats
const lgZoom = require('lightgallery/plugins/zoom');
window.lgZoom = lgZoom.default || lgZoom;

const lgVideo = require('lightgallery/plugins/video');
window.lgVideo = lgVideo.default || lgVideo;

const lgThumbnail = require('lightgallery/plugins/thumbnail');
window.lgThumbnail = lgThumbnail.default || lgThumbnail;

const lgFullscreen = require('lightgallery/plugins/fullscreen');
window.lgFullscreen = lgFullscreen.default || lgFullscreen;

const lgAutoplay = require('lightgallery/plugins/autoplay');
window.lgAutoplay = lgAutoplay.default || lgAutoplay;

// Load Moment.js
const moment = require('moment');
window.moment = moment;

// Load RecordRTC
const RecordRTC = require('recordrtc');
window.RecordRTC = RecordRTC;
window.StereoAudioRecorder = RecordRTC.StereoAudioRecorder; 